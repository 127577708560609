import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './home.css'; // Import the global styles
import App from './App';
import Next from './Next';
import CaptchaVerification from './CaptchaVerification';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<App />} /> */}
    
      <Route path="/" element={<CaptchaVerification />} /> 
      <Route path="/App" element={<App />} />
      <Route path="/Next" element={<Next />} />  
    </Routes>
    
    </BrowserRouter>
  </React.StrictMode>
);

