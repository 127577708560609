import React, { useState, useEffect } from 'react';
import './styles.css';
 import axios from 'axios';
 import App from './App';
 import Home from './Home';
 import logo from './logo.svg';
 import { useNavigate  } from "react-router-dom";
 

const CaptchaVerification = () => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(false);
   const [ipAddress, setIpAddress] = useState('');
  const [userAgent, setUserAgent] = useState('');
  const [error, setError] = useState(false);

  const [data, setData] = useState({
    ipAddress,
    userAgent
  })
  // const navigate = useNavigate();

  useEffect(() => {
    const isVerified = localStorage.getItem('isUnlocked');
    if (isVerified === 'true') {
      setIsUnlocked(true);
      setShowLandingPage(true);
      // navigate('/App'); // Navigate to the 'App' component
    }
    
        if (window.localStorage) {
          axios
            .get('https://api.ipify.org?format=json')
            .then(response => {
              setIpAddress(response.data.ip);
            })
            .catch(error => {
              console.error('Error fetching IP address:', error);
            });
        }
    
        setUserAgent(navigator.userAgent);
      }, []);

      
  const startSlide = (event) => {
    // event.preventDefault();
    const startPosition = event.clientX || event.touches[0].clientX;

    const moveSlide = (event) => {
      // event.preventDefault();
      const x = event.clientX || event.touches[0].clientX;
      const distance = x - startPosition;
      const containerWidth = event.target.parentElement.clientWidth;
      const maxSlideDistance = containerWidth - event.target.offsetWidth;
      const newPosition = Math.max(0, Math.min(distance, maxSlideDistance));

      if (!isUnlocked && newPosition >= 190) {
        setIsUnlocked(true);
        setShowLandingPage(true);
        localStorage.setItem('isUnlocked', 'true');
      }

      setCurrentPosition(newPosition);
    };

    const endSlide = () => {
      document.removeEventListener('mousemove', moveSlide);
      document.removeEventListener('touchmove', moveSlide);
      document.removeEventListener('mouseup', endSlide);
      document.removeEventListener('touchend', endSlide);

      if (!isUnlocked) {
        setCurrentPosition(0);
        setError(true);
      }

      document.addEventListener('mousedown', startSlide);
      document.addEventListener('touchstart', startSlide);
    };

    document.addEventListener('mousemove', moveSlide);
    document.addEventListener('touchmove', moveSlide);
    document.addEventListener('mouseup', endSlide);
    document.addEventListener('touchend', endSlide);

    document.removeEventListener('mousedown', startSlide);
    document.removeEventListener('touchstart', startSlide);
  };

  return (
    <>
      {showLandingPage ? (
        
       <App 
       setUserAgent={setUserAgent}
       setIpAddress={ipAddress}

       />
      ) : (
        <div  className="container flex justify-center items-center">

       
        <div className="center flex justify-center items-center">
         <img src={logo} className="App-logo" alt="logo" />
          <h1>Verify You're Human</h1>
          <div className="captcha-container">
            <button
              id="slider"
              className={`slider ${isUnlocked ? 'unlocked' : ''}`}
              style={{
                                backgroundImage: `url("https://i.postimg.cc/x1qR9tzv/swipe.png")`,
                                backgroundSize: 'cover',
                                left: `${currentPosition}px`,
                                transition: 'left 0.3s',
                                width: '44px',
                                height: '44px',
                                borderRadius: isUnlocked ? '50%' : 'none',
                                border: 'none',
                              }}
              onMouseDown={startSlide}
              onTouchStart={startSlide}
            ></button>
            <span className="label">
              {isUnlocked ? 'Verification successful!' : 'Swipe right to proceed'}
            </span>
          </div>
          <p>
          {error && (
            <span className="error">
              CAPTCHA verification failed.
              </span>
            )}
          </p>
        </div>
        </div>
      )}
    </>
  );
}

export default CaptchaVerification;
